import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import axios from 'axios'
import { postAddClaim } from '../../actions/claims';

import '../../css/ActiveClaims.css';
import '../../css/AdjustmentsActiveClaims.css';

import MainWrapper from '../wrappers/mainWrapper';

const MAX_COUNT = 2;

const ActiveClaims = props => {
    const [loadingStatus, setLoadingStatus] = useState('loading-spinner');
    const [responded, setResponded] = useState(false);
    const [comps, compsSet] = useState(["NaN"]);
    const [response, setResponse] = useState({})
    const user = useSelector(state => state.user)
    const userData = useSelector(state => state.user._id)
    const userAdmin = useSelector(state => state.user.admin);
    const userEmail = useSelector(state => state.user.email);
    const userActive = useSelector(state => state.user.isActive);
    const [amount, setAmount] = useState(0)
    const [description, setDescription] = useState("")
    const [calculateResponded, setCalculateResponded] = useState(false)
    const [count, setCount] = useState(0);

    const [claimData, setClaimData] = useState({
        adj_email : "",
        adj_phone: "",
        adjuster: "",
        claim_type: "",
        date_open: "",
        insurance_comp: "",
        insured: "",
        insured_address: "",
        insured_phone: "",
        prop_type: "",
        user_id: ""
    })

    console.log("status: " + JSON.stringify(user?.baseSubscription?.status,null,2))
    
    useEffect(() => {
        let time = 2000
        if (responded) {
            time = 3000
        }
        const interval = setInterval(() => {                
            getResults(userData);
            if (!calculateResponded && responded) {
                console.log("calculate");
                calculateAdditionalBill();
            }
            console.log("calling");
        }, time); // 2000ms = 2 seconds

        // Clean up the interval on component unmount or when dependencies change
        return () => clearInterval(interval);
    }, [userData, responded, calculateResponded, comps]);

    function calculateAdditionalBill() {
        if(userData?.baseSubscription?.status != "trialing") {
            const periodStart = user?.baseSubscription?.current_period_start
        const periodEnd = user?.baseSubscription?.current_period_end
        let countable = 0
        console.log("periosStart: " + periodStart)
        console.log("periodEnd: " + periodEnd)
        if(comps != undefined || comps != null) {
          for(let i = 0; i < comps.length; i++) {
            let date = new Date(comps[i].dateUploaded)
            //console.log("date: " + date)
            let utcDate = Math.floor((date.getTime() + (68800 * 1000)) / 1000)
            console.log("utcDate: " + utcDate)
            if (utcDate > periodStart && utcDate < periodEnd && (comps[i].status === "Finished")) {
                console.log("if : ")
              countable++
            }
          }
        }
        console.log("cacluclated countable: " + countable)
        console.log("monthly_allotment: " + user?.monthly_allotment)
        if ((countable - user?.monthly_allotment) > 0){
          const calculatedAmount = (countable - user?.monthly_allotment) * user?.additional_cost
          setAmount(calculatedAmount)
      
          const writeDescription = `Additional Comparison (${(comps[comps.length - 1])._id.ID}) Used`
          setDescription(writeDescription)
          axios.post(process.env.REACT_APP_ADD_INVOICE_ITEM, {
            customerId: user?.stripeCustomer?.id,
            amount: user?.additional_cost,
            description: writeDescription,
            subscriptionId: user?.baseSubscription?.id,
            monthlyAllotment: user?.monthly_allotment,
            count: countable
        })
        }
        
        setCalculateResponded(true)
        }
        
      }
      
    const inline = {
        display: 'flex',
        justifyContent: 'flex-end'
    }
    function renderCreateNewButton() {
        if (user?.admin) {
            return(
                <button className="create-new-comparison-button"><NavLink to="/createNewComparison" className="navlink-style-create-new">Create New Comparison</NavLink></button>                
            )

        }
        else if(user?.baseSubscription?.status === "active" && user?.isActive) {
            return(
                <button className="create-new-comparison-button"><NavLink to="/createNewComparison" className="navlink-style-create-new">Create New Comparison</NavLink></button>                
            )
        }
        else if(user?.baseSubscription?.status === "trialing") {
            return(
                <button className="create-new-comparison-button"><NavLink to="/createNewComparison" className="navlink-style-create-new">Create New Comparison</NavLink></button>                
            )
        }
        else {
            return
        }
    }

    //change the output of the status to be more user-friendly. 
    function changeStatusOutput(item) {
        if(item["status"] === "Finished") {
            return (
                "Finished"
            )
        }
        else if(item["status"] === "Pending") {
            return (
                "Pending"
            )
        }
        else if(item["status"] === "Failed") {
            return (
                "This one stumped me."
            )
        }
        else if (item["status"] === "Archived") {
            return (
                "Archived"
            )
        }
        else {
            return (
                "-"
            )
        }
    }

    function getMessage(item) {        
        if(item["statusMSG"] != undefined) {
            return item["statusMSG"]
        }
    }

    //delete comparison at specific id
    function deleteComparison(id) {
        {/*Axios Delete request needs work. get a net ERR Failed 503*/}

        axios.delete((`${process.env.REACT_APP_DELETE_COMPARISON}${id}`))
            .then(response => 
                console.log("Delete Successful: " + response.status))
            .catch(error => {
                console.log("Delete Failed: " + error);
            });
    }

    
    //insert into renderDeleteButton onClick={deleteComparison((item["_id"]["ID"]))}
    //return specific button
    function renderDeleteButton(item) {
        if(userAdmin == true && (userEmail === ( "andrew@pdgcreative.com") || userEmail === ("john.coday@pdgcreative.com") || userEmail === ("travis+levlr.test@pdgcreative.com") || userEmail === ( "caleb.williams@pdgcreative.com"))){
            return (
                <button id={(item["_id"]["ID"])} onClick={() => deleteComparison((item["_id"]["ID"]))} className={'delete-comparison-button'}>Delete</button>
            )
        }
        else {
            return
        }
    }
    
    function showAlertID(id) {
        alert("Comparison ID: " + id);
    }

    //check status of comparison. return appropriate button. 
    function checkComparisonStatus(item) {
        if(item["status"] ==="Pending") {
            return(
                <button onClick={() => showAlertID(item["_id"]["ID"])} className={'comparison-pending-button'}>
                    Pending
                </button>
            )
        }
        else if (item["status"] ==="readyToContinue") {
            return(
                <button className={'comparison-continue-button'}>
                    <NavLink to={continueComparison(item["_id"]["ID"])} className={'view-results-button-nav white'}>Input Needed</NavLink>
                </button>
            )
        }
        else if(item["status"] ==="Failed") {
            return(
                <button onClick={() => showAlertID(item["_id"]["ID"])} className={'comparison-failed-button'}>
                    Unavailable
                </button>
            )
        }
        else if(item["statusMSG"] === "In queue") {
            return(
                <button onClick={() => showAlertID(item["_id"]["ID"])} className={'comparison-pending-button'}>
                    Queued
                </button>
            )
        }
        else if(item["status"] === "Archived") {
            return(
                <button onClick={() => showAlertID(item["_id"]["ID"])} className={'comparison-failed-button'}>
                    Archived
                </button>
            )
        }
        else {
            return(
                <button className={'view-results-button'}>
                    <NavLink to={comparisonResults(item["_id"]["ID"])} className={'view-results-button-nav white'}>View Results</NavLink>
                </button>
            )
        }
    }

    function comparisonResults(id) {
        /* REPLACE THIS [static value] */
        const comparisonID = id

        //return "/comparisonResults/" + "64bee0f6b9e6d4b8e9ab8900";
        return "/comparisonResults/" + comparisonID
    }

    function continueComparison(id) {
        const comparisonID = id
        return "/continueComparison/" + comparisonID
    }
    
    const getResults = async (id) => {
        const response = await fetch(
            `${process.env.REACT_APP_RESULTS_DATA}user/${id}`
        ).then((response) => response.json({}));
        compsSet(response)
        setResponded(true)
    }

    function checkDate(date) {
        if (date != undefined) {
            return date
        }
        else if (date == undefined) {
            return ("-")
        }
    }

    function checkMsgStatus(msg, status) {
        if (status != "Failed") {
            if ((msg === "Parsing Document 1") || (msg === "Parsing Document 2")) {
                return "loading-spinner-small"
            }
            else if (status === "readyToContinue"){
                return "checkmark"
            }
            else {
                return ""
            }
        }
    }

    const statusMsg = {
        overflow: 'auto',
        justifyContent: 'space-between'
    }

    function resultsList () {
        
        if(comps[0] == "NaN"){
            return(
                <div className={'on-load-message-container'}>
                    <p className={'loading-comparisons-text'}> Loading Your Comparisons... </p>
                    <div className={loadingStatus}></div>
                    
                </div>
            )
        
        }
        else if (user?.admin) {
            const reversed = [...comps].reverse();
            return(
                <table style={{overflow: 'auto'}}>
                    <thead>
                        <tr>
                            <th>Adjuster Name</th>
                            <th>Insured Name</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Message</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody style={{overflow: 'auto'}}>
                        
                        {
                            // order from latest to oldest
                            reversed.map((item, index) => 
                            <tr key={index}>
                                <td>{item["claimData"]["adjuster"]}</td>
                                <td>{item["claimData"]["insured"]}</td>
                                <td>{checkDate(item["dateUploaded"])}</td>
                                <td>{changeStatusOutput(item)}</td>
                                <td style={statusMsg}>{getMessage(item)}<div className={checkMsgStatus(getMessage(item), item["status"])}></div></td>                                
                                <td style={inline}>
                                    {checkComparisonStatus(item)}
                                    {renderDeleteButton(item)}
                                </td>
                            </tr> 
                            )
                        }
                    </tbody>
                    
                </table>
               
            )
        }
        else if (!user?.isActive) {
            return(
                <p>Your account is disabled. Please Contact Support. </p>
            )
        }
        else if((comps?.length === 0) && user?.baseSubscription?.status === "active" && user?.isActive){
            return(
                <div className={'on-load-message-container'}>
                    <p className={'navlink-style-start-here-p'}>Hey! Looks like you're new to these parts. <NavLink to="/createNewComparison" className="navlink-style-start-here">START HERE.</NavLink></p>
                </div>
            )
        }
        else if((comps?.length === 0) && user?.baseSubscription?.status === "trialing"){
            return(
                <div className={'on-load-message-container'}>
                    <p className={'navlink-style-start-here-p'}>Hey! Looks like you're new to these parts. <NavLink to="/createNewComparison" className="navlink-style-start-here">START HERE.</NavLink></p>
                </div>
            )
        }

        else if(((user?.baseSubscription === undefined) || (user?.baseSubscription === null) || (user?.baseSubscription?.status === "past_due") || (user?.baseSubscription?.status === "unpaid"))){
            return(
                <div className={'on-load-message-container'}>
                    <p className={'navlink-style-start-here-p'}>Your subscription is not active <NavLink to="/accounts" className="navlink-style-start-here">CLICK HERE.</NavLink></p>
                </div>
            )
        }
        
        else {
            const reversed = [...comps].reverse();
            return(
                <table style={{overflow: 'auto'}}>
                    <thead>
                        <tr>
                            <th>Adjuster Name</th>
                            <th>Insured Name</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Message</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody style={{overflow: 'auto'}}>
                        
                        {
                            // order from latest to oldest
                            reversed.map((item, index) => 
                            <tr key={index}>
                                <td>{item["claimData"]["adjuster"]}</td>
                                <td>{item["claimData"]["insured"]}</td>
                                <td>{checkDate(item["dateUploaded"])}</td>
                                <td>{changeStatusOutput(item)}</td>
                                <td style={statusMsg}>{getMessage(item)}<div className={checkMsgStatus(getMessage(item), item["status"])}></div></td>                                
                                <td style={inline}>
                                    {checkComparisonStatus(item)}
                                    {renderDeleteButton(item)}
                                </td>
                            </tr> 
                            )
                        }
                    </tbody>
                    
                </table>
               
            )
        }
    }

    return (

        <div className={'outer-container'}>
            <MainWrapper/>
            <div className={'right-container'}>

                <div style={{overflow: 'auto'}} className={'create-new-claim-container'}>

                    <h1 className={'h1-create-new-claim'}>Active Comparisons</h1>
                    
                    <div className="create-new-container">    
                        {renderCreateNewButton()}
                    </div>

                    <div style={{overflow: 'auto'}} className={'all-comparisons-table'}>
                        { resultsList() }            
                    </div>

                </div>

            </div>
        </div>
    )}

export default ActiveClaims